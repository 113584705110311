<template>
  <div class="hello">
    <el-container>
      <el-header>
        <!--          搜索框-->
        <el-row justify="space-between" type="flex" class="header-row">
          <el-col :xs="8" :lg="6" style="text-align: left">
            <el-select v-model="orgPlayId" placeholder="请选择" @change="orgPlayItemChange">
              <el-option
                  v-for="(item,index) in videoPlatform"
                  :key="index"
                  :label="item.resName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :xs="16" :lg="8">
            <div>
              <el-input placeholder="这里搜索视频" v-model="eachname" clearable class="input-with-select">
                <el-button slot="append" icon="el-icon-search" @click="eachVideo"></el-button>
              </el-input>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <el-row justify="center" type="flex">
          <!--          跑马灯-->
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="10">
            <el-carousel :height="bannerHeight" direction="vertical" :autoplay="true">
              <el-carousel-item v-for="(item, index) in showMedia" :key="index">
                <el-image :src="'/api/resources/public/banner/' + item.bearImg" fit="fit"
                          :style="'width: 100%; height:'  + bannerHeight" @click="bannerjump(item.bearUrl)"/>
              </el-carousel-item>
            </el-carousel>
          </el-col>
        </el-row>
        <el-divider>李棋同学</el-divider>
        <div v-loading="loading"
             element-loading-text="大橙拼命加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-row>
            <el-col :xs="8" :sm="6" :md="6" :lg="3" :xl="4" v-for="(item,index) in videoList" :key="index">
              <a @click="clickToJump(item)">
                <el-card :body-style="{ padding: '4px' , 'margin-top':'4px' ,height:bannerHeight}">
                  <img :src=item.img class="image">
                  <div class="marquee">
                    <div class="marquee-wrap">
                      <span class="marquee-content" :title="item.title">{{ item.title }}</span>
                    </div>
                  </div>
                </el-card>
              </a>
            </el-col>
          </el-row>
        </div>
      </el-main>
      <!--      <el-footer>CHARON © 2020-2024 <a href="https://beian.miit.gov.cn"-->
      <!--                                       style="text-decoration: none;">湘ICP备20011896号</a></el-footer>-->
    </el-container>

    <el-dialog
        title="免责声明"
        top="5vh"
        :visible.sync="indexDialogHint"
        width="80%">
      <p style="text-align: center">微信扫码关注公众号
        <el-image src="https://file.charonv.com/gzh.jpg"/>
      </p>
      <span>本网站所涉及的技术手段及实现过程，仅作为爬虫技术学习使用，不对任何人完全或部分地依据本文的全部或部分内容从事的任何事情和因其任何作为或不作为造成的后果承担任何责任。
        本站所有接口均来至互联网仅提供本地学习使用，不得用于任何商业用途，数据来源于互联网公开内容，没有获取任何私有和有权限的信息（个人信息等）。由此引发的任何法律纠纷与本人无关！请勿用于任何商业用途，否则后果自负。
        大橙同学的影视资源全部来源互联网，如有版权问题，请来信说明，本站会第一时间进行处理。 <span style="color: red">联系邮箱: dcen@charonv.com</span></span>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="closeDialog">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import initialAllReq from "@/request/initialAllReq";
import mgtvAnalyze from "@/spider/mgtv";
import {tvTypeJudge} from "@/utils/TvUtil";
import tvAsk from "@/request/expand/tv";
import Banner from "../../request/banner";
import banner from "../admin/banner.vue";

export default {
  name: 'index',
  computed: {
    banner() {
      return banner
    }
  },
  data() {
    return {
      askBanner: new Banner(),
      HttpInitialAll: new initialAllReq(),
      mgtvAnalyze: new mgtvAnalyze(),
      eachname: '',
      videoList: null,
      bannerHeight: '185px',
      screen: '0px',
      imgWidth: '100%',
      imgHeight: '100%',
      orgPlayItem: null, //搜索视频平台
      orgPlayId: "", //搜索视频平台
      videoPlatform: [],
      loading: false,
      indexDialogHint: false,
      showMedia: null
    }
  },
  methods: {
    bannerjump(url) {
      if (url != null && url !== '') {
        window.open(url, "_blank");
      }
    },
    closeDialog() {
      this.indexDialogHint = false;
      this.$storageHandler.setSessionStorage("indexDialogHint", false);
    },
    getSize() {
      // eslint-disable-next-line no-debugger
      // debugger
      let b = this.screen / 400
      if (b < 1.92) {
        this.bannerHeight = b * 200 + 'px'
      } else if (b >= 1.92 && b < 3) {
        this.bannerHeight = b * 150 + 'px'
      } else {
        this.bannerHeight = b * 75 + 'px'
      }
      console.log(b)

    },
    /**
     * 点击视频挑战播放
     */
    clickToJump(item) {
      const cacheOrgType = this.$storageHandler.getLocalStorage('orgPlayItem');
      if (cacheOrgType == null) {
        this.$message({
          showClose: true,
          message: '客户端错误, 请重新搜索资源',
          type: 'error'
        });
        this.videoList = [];
        this.$storageHandler.removeLocalStorage('indexListData');
        return;
      }
      let spiderId = tvTypeJudge(cacheOrgType, item);
      // let playType = cacheOrgType.resSource === 'cronTv' ? item['desc'] : cacheOrgType.resPlayType
      let playType = cacheOrgType.resPlayType
      this.$router.push({
        name: 'videoPlay',
        query: {
          id: item.link,
          titleName: item.title,
          tvImg: item.img,
          spiderId: spiderId,
          playType: playType
        }
      })
    },

    /**
     * 搜索视频
     */
    eachVideo() {
      this.$storageHandler.setLocalStorage('eachTvName', this.eachname);
      let data = {
        'tvName': this.eachname,
        'spiderId': this.orgPlayItem.resSpiderId,
        'resName': this.orgPlayItem.resSource
      }
      this.loading = true;
      this.HttpInitialAll.eachVideoReq(data).then(result => {
        const res = result.msg;
        this.videoList = res;
        this.$storageHandler.setLocalStorage("orgPlayItem", this.orgPlayItem);
        this.$storageHandler.setLocalStorage("indexListData", res);
        this.loading = false;
      }).catch(err => {
        this.loading = false;
      })
    },
    /**
     * 资源源id改变赋值实体
     */
    orgPlayItemChange(id) {
      const index = this.videoPlatform.findIndex((item) => item.id == id);
      this.orgPlayItem = this.videoPlatform[index]
    },
  },
  mounted() {
    this.eachname = this.$storageHandler.getLocalStorage('eachTvName');
    const indexDialogHint = this.$storageHandler.getSessionStorage("indexDialogHint");
    this.indexDialogHint = indexDialogHint == null ? true : indexDialogHint;
    this.videoList = this.$storageHandler.getLocalStorage('indexListData');
    this.screen = window.innerWidth
    this.getSize()
    window.onresize = () => {
      this.screen = window.innerWidth
      this.getSize()
    };
  },
  created() {
    this.askBanner.getData().then(res => {
      this.showMedia = res
    })
    new tvAsk().getTvResConfig().then(res => {
      this.$store.commit('systemConfig/setTvResSearch', res.msg)
      this.videoPlatform = res.msg
      const cacheOrgType = this.$storageHandler.getLocalStorage('orgPlayItem');
      this.orgPlayId = cacheOrgType == null ? "" : cacheOrgType.id;
      // 保持最新的配置
      this.orgPlayItem = res.msg.find(item => item.id == this.orgPlayId);
      this.$storageHandler.setLocalStorage("orgPlayItem", this.orgPlayItem);
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-footer {
  text-align: center
}

.header-row {
  background-image: linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%);
  padding: 7px 0;
  border-radius: 5px;
}

.el-header {
  padding: 0;
}


.el-aside {
  background-color: #ebedee;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background: #fdfbfb;
  color: #333;
  text-align: center;
  padding: 0;
  /*line-height: 160px;*/
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

a {
  text-decoration: none
}

.owen {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 13px;
  padding-top: 5px;
}

.marquee {
  overflow: hidden;
}

.marquee .marquee-wrap {
  width: 100%;
  animation: marquee-wrap 4s infinite linear;
}

.marquee .marquee-content {
  padding-top: 5px;
  float: left;
  white-space: nowrap;
  min-width: 100%;
  animation: marquee-content 4s infinite linear;
}

@keyframes marquee-wrap {
  0%,
  30% {
    transform: translateX(0);
  }
  70%,
  100% {
    transform: translateX(100%);
  }
}

@keyframes marquee-content {
  0%,
  30% {
    transform: translateX(0);
  }
  70%,
  100% {
    transform: translateX(-100%);
  }
}

/deep/ .el-dialog__body {
  padding-top: 0px;
}
</style>
