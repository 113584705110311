/** 芒果tv数据解析
 * Author :charon
 */

export default class mgtvAnalyze {

    /**
     * 解析index.vue 搜索框搜索后返回页面呈现数据
     * @param jsonData
     * @returns {[]}
     */
    mgtvApiAnalyze(jsonData){
        let resultData = [];
        let content = jsonData.data.contents;
        for(let i = 0;i<content.length;i++){
            const item = content[i];
            if(item.name == "媒资头部"){
                let titleItemData = item.data[0].title;
                let title = titleItemData.replaceAll("<B>","").replaceAll("</B>","");
                let obj = {
                    img:item.data[0].img,
                    link:item.data[0].url,
                    title:title
                }
                resultData.push(obj)
            }
        }
        return resultData;
    }
}
